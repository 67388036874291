import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/core/auth/auth.service';
import { RolesService } from 'app/modules/admin/roles/roles.service';
import { UsersService } from '../../users.service';
import { UserService } from 'app/core/user/user.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  userForm: FormGroup;

  role_loader: Boolean = false;
  email_used = false;
  pass = false;
  pass_identic = false;
  hide: Boolean = true;
  watching = false;
  editing = false;

  loading = false;
  loading_page = false;

  roles: any = [];

  validation_messages = {
    password: [
      { type: "required", message: 'Email es requerido' },
      { type: "minLength", message: 'Minímo 3 caracteres' }
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private _usersService: UsersService,
    private roleService: RolesService,
    private _authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;

    this.userForm = this.formBuilder.group({
      password_1: new FormControl(''),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3)])),
      confirmPassword: new FormControl('')
    },
      {
        validator: this.MatchPassword,
      });
  }

  ngOnInit(): void {
  }

  save() {
    let form = this.userForm.value;
    form = { ...form, id_user: this.data.id_user };
    this.loading = true;

    this._usersService.changePassword(form).subscribe((res: any) => {
      if (res.status == 200) {
        this.dialogRef.close('updated');
        if(form.id_user == this._authService.user.id_user){
          this._authService.accessToken = res.token
        }
        this._snackBar.open("Cambio de contraseña exitoso", "ok", { duration: 2000 });
      } else {
        if (res.message == 'Incorrect Password') {
          this.pass = true;
          setTimeout(() => this.pass = false, 3000);
        } else if(res.message == "Can't use the same password"){
          this.pass_identic = true;
          setTimeout(() => this.pass_identic = false, 3000);
        }
      }
      this.loading = false;
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  private MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value // to get value in input tag
    const passconfirm = AC.get('confirmPassword').value // to get value in input tag
    if (password != passconfirm) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      AC.get('confirmPassword').setErrors(null);
    }
  }

  generatePassword() {
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '{', '[', '}', ']', '>', '<', '.', '?', '/'];
    let capitalLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    let lowercase = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

    let passwordG = '';

    for (let i = 0; i < 1; i++) {
      passwordG += numbers[Math.floor(Math.random() * numbers.length)];
      passwordG += symbols[Math.floor(Math.random() * symbols.length)];
      passwordG += capitalLetters[Math.floor(Math.random() * capitalLetters.length)];
    }

    for (let o = 0; o < 5; o++) {
      passwordG += lowercase[Math.floor(Math.random() * lowercase.length)];
    }

    let passFin = passwordG.split('');
    let pass = '';
    let j, k;

    for (let e = passFin.length; e; e--) {
      j = Math.floor(Math.random() * e);
      k = passFin[e - 1];
      passFin[e - 1] = passFin[j];
      passFin[j] = k;
    }

    pass = passFin.toString();
    pass = pass.replace(/,/g, "");

    this.userForm.patchValue({
      password: pass,
      confirmPassword: pass
    })
  }

}
