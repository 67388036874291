<div class="flex justify-end">
    <button mat-icon-button class="close" (click)="onNoClick()">
        <mat-icon style="color: red;">close</mat-icon>
    </button>
</div>
<div class="body w-full">
    <p>1. Para activar la autenticación en dos pasos instale <b>Google Authenticator</b> en el telefono y escanee el
        código QR.</p>
    <div *ngIf="data.status != 403" class="content-qr p-4">
        <img [src]="data.qr">
    </div>
    <p>2. Escriba el código generado por <b>Google Authenticator</b> y presione aceptar.</p>
    <form [formGroup]="twoFactorForm" class="p-2 w-full form1">
        <mat-form-field class="w-full">
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Ingresa el código" formControlName="code" />
            <mat-hint>
                <label *ngIf="errorCode" class="required text">Código incorrecto</label>
            </mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="data.password == ''" class="w-full">
            <mat-label>Contraseña<mat-label *ngIf="!editing" class="required">*</mat-label>
            </mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>
                <label *ngIf="errorPass" class="required text">Contraseña incorrecta</label>
            </mat-hint>
        </mat-form-field>
    </form>
    <button mat-flat-button id="save" style="width: 100px;" (click)="ingresar()"
        [disabled]="!twoFactorForm.valid || loading">
        <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
        <span *ngIf="!loading">Aceptar</span>
    </button>
</div>