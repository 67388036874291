import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit {

  twoFactorForm: FormGroup;
  loading: Boolean = false;
  errorCode: Boolean = false;
  errorPass: Boolean = false;
  hide: Boolean = true;

  constructor(public dialogRef: MatDialogRef<TwoFactorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private _authService: AuthService,
    private _snackBar: MatSnackBar) {
    this.twoFactorForm = this.fb.group({
      email: new FormControl(this.data.email),
      password: new FormControl(this.data.password),
      secret: new FormControl(this.data.key),
      code: new FormControl("", Validators.required)
    });
  }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close({ active: true });
  }

  ingresar() {
    this.loading = true;

    this._authService.loginTwoFactor(this.twoFactorForm.value).subscribe((response: any) => {
      if (response.status == 200) {
        this._snackBar.open("Verificación completada", "ok", { duration: 2000 });
        this.dialogRef.close({ active: false });
      } else {
        if (response.message == "Incorrect Password") {
          this.errorPass = true;
          setTimeout(() => this.errorPass = false, 3000);
        } else {
          this.errorCode = true;
          this.twoFactorForm.get('code').reset();
          setTimeout(() => this.errorCode = false, 3000);
        }
      }
      this.loading = false;
    })

  }
}
