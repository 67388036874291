import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
    private _authService: AuthService,
  ) { }

  getSuperUsers(page, limit, by, order, search?) {
    let headers = new HttpHeaders();

    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    let url = `/users?order_by=${by}&order=${order}&page=${page}&limit=${limit}`;
    if (search) {
      url = `${url}&search_pattern=${search}`;
    }

    return this.http.get(`${environment.API_URL}${url}`, {
      headers: _headers
    });
  }

  getUser(id) {
    let headers = new HttpHeaders();

    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.get(`${environment.API_URL}/users/${id}`, {
      headers: _headers,
    });
  }

  createUser(form) {
    let fieldForm = new FormData();

    for (let field in form) {
      fieldForm.append(field, form[field])
    }

    let headers = new HttpHeaders();

    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/auth/register`, fieldForm);

  }

  editUser(form) {
    let fieldForm = new FormData();

    for (let field in form) {
      if (field != 'password') {
        fieldForm.append(field, form[field])
      }
    }

    let headers = new HttpHeaders();

    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/users/${form.id_user}/edit`, fieldForm, {
      headers: _headers,
    });
  }

  editProfile(form) {
    let fieldForm = new FormData();

    for (let field in form) {
      if (field != 'password') {
        fieldForm.append(field, form[field])
      }
    }

    let headers = new HttpHeaders();

    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/users/${form.id_user}/edit-self`, fieldForm, {
      headers: _headers,
    });
  }

  changePassword(form) {
    let fieldForm = new FormData();

    fieldForm.append('new_password', form.password);
    fieldForm.append('password', form.password_1);

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/users/${form.id_user}/change-password`, fieldForm, {
      headers: _headers,
    });
  }

  deleteSuperUser(_id, force) {
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.delete(`${environment.API_URL}/users/${_id}/delete?force=${force}`, {
      headers: _headers,
    });
  }

  searchUsers(search, page, limit, by, order, status?) {
    let company = localStorage.getItem('sto_company');

    let headers = new HttpHeaders();

    if (company == 'stephy-tracking') {
      headers = new HttpHeaders({
        'token': this._authService.accessToken,
        'ApiKey': environment.ApiKey
      })
    } else {
      headers = new HttpHeaders({
        'token': this._authService.accessToken,
        // 'ApiKey': this.vars.companyInfo.api_key
      })
    }

    let url;

    if (status) {
      url = `${environment.API_URL}/${company}?endpoint=all_users&limit=${limit}&page=${page}&order_by=${by}&order=${order}&active=${status}&pattern_search=${search}`;
    } else {
      url = `${environment.API_URL}/${company}?endpoint=search_users&pattern=${search}&page=${page}&limit=${limit}&sort_by=${by}&order=${order}`;
    }

    return this.http.get(url, {
      headers: headers,
    });
  }

  checkUserId(user) {
    let company = localStorage.getItem('sto_company');

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.get(`${environment.API_URL}/${company}/users/available?user_id=${user}`, {
      headers: _headers,
    });
  }

  getBrowsers() {
    let company = localStorage.getItem('sto_company');

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.get(`${environment.API_URL}/${company}/browsers/list`, {
      headers: _headers,
    });
  }

  deleteBrowser(browsers) {
    let company = localStorage.getItem('sto_company');
    let browser = new FormData();

    browser.append('browsers_id', JSON.stringify(browsers))
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/${company}/browsers/delete`, browser, {
      headers: _headers,
    });
  }
}
