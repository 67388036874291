<div class="body w-full" *ngIf="loading_page">
    <mat-spinner [diameter]="60"></mat-spinner>
</div>
<div class="w-full sm:p-6 p-0" *ngIf="!loading_page">
    <div class="header">
        <h1 class="title"><strong>Cambiar Contraseña</strong></h1>
        <button mat-icon-button class="ml-4" (click)="onNoClick()">
            <mat-icon class="close">close</mat-icon>
        </button>
    </div>

    <form class="grid grid-cols-1 gap-4"
        [formGroup]="userForm">

        <mat-form-field class="password-field">
            <mat-label>Contraseña actual</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password_1">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>
                <label *ngIf="pass" class="required text">
                    Contraseña incorrecta</label>
                <ng-container *ngFor="let validation of validation_messages.password">
                    {{ userForm.get("password_1").hasError(validation.type) &&
                    (userForm.get("password_1").dirty || userForm.get("password_1").touched) ?
                    validation.message : ""}}
                </ng-container>
            </mat-hint>
        </mat-form-field>

        <div class="block-password" *ngIf="!watching">
            <div class="buttons">
                <button mat-icon-button [color]="'accent'" (click)="generatePassword()">
                    <mat-icon>password</mat-icon>
                </button>
            </div>
            <mat-form-field class="password-field">
                <mat-label>Contraseña<mat-label *ngIf="!editing" class="required">*</mat-label>
                </mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint>
                    <label *ngIf="pass_identic" class="required text">
                        No se puede actualizar con la misma contraseña</label>
                </mat-hint>
                <mat-error>
                    <ng-container *ngFor="let validation of validation_messages.password">
                        {{ userForm.get("password").hasError(validation.type) &&
                        (userForm.get("password").dirty || userForm.get("password").touched) ?
                        validation.message : ""}}
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>



        <mat-form-field *ngIf="!watching">
            <mat-label>Confirmar Contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>
                <!-- <ng-container *ngFor="let validation of validation_messages.confirmPassword">
                    {{ userForm.get("confirmPassword").hasError(validation.type) &&
                    (userForm.get("confirmPassword").dirty || userForm.get("confirmPassword").touched) ?
                    validation.message : ""}}
                </ng-container> -->
                <div class="validation" *ngIf="userForm.controls['confirmPassword'].errors?.MatchPassword">
                    Las contraseñas no coinciden
                </div>
            </mat-hint>
        </mat-form-field>
    </form>

    <div class="mt-6 btn_container">
        <button mat-flat-button class="save mr-3" [disabled]="!userForm.valid || loading"
            (click)="save()">
            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            <span *ngIf="!loading">Guardar</span>
        </button>

        <button mat-flat-button class="cancel mr-3" (click)="onNoClick()">
            Cancelar
        </button>
    </div>
</div>