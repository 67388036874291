import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private http: HttpClient,
    private _authService: AuthService,
  ) { }

  listRoles(order) {
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);
    
    return this.http.get(`${environment.API_URL}/role?page=1&limit=999&order=${order}`, {
      headers: _headers
    });
  }

  getRole(id) {
    let company = localStorage.getItem('sto_company');
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);
    
    return this.http.get(`${environment.API_URL}/${company}/roles/${id}`, {
      headers: _headers
    });
  }

  listSections(id_role) {
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.get(`${environment.API_URL}/section/get-sections?id_role=${id_role}`, {
      headers: _headers,
    });
  }


  editPermission(form) {
    let permissionForm = new FormData();
    permissionForm.append("id_role", form.id_role);
    permissionForm.append("id_section", form.id_section);
    permissionForm.append("create", form.create);
    permissionForm.append("edit", form.edit);
    permissionForm.append("list", form.list);
    permissionForm.append("delete", form.delete);
    permissionForm.append("view", form.view);
    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);
    
    return this.http.post(`${environment.API_URL}/permission/${form.id_permission}/edit`, permissionForm, {
      headers: _headers,
    });
  }

  /*newPermission(form) {
    let company = localStorage.getItem('sto_company');

    let permissionForm = new FormData();
    permissionForm.append("id_role", form.role.toString());
    permissionForm.append("id_section", form.section.toString());
    permissionForm.append("create", form.create);
    permissionForm.append("edit", form.edit);
    permissionForm.append("list", form.list);
    permissionForm.append("delete", form.delete);
    permissionForm.append("view", form.view);

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/${company}/roles/permissions/create`, permissionForm, {
      headers: _headers,
    });
  }

  newRole(form) {
    let company = localStorage.getItem('sto_company');

    let roleForm = new FormData();
    let name = form.name.toString();
    let Name = name[0].toUpperCase() + name.substr(1).toLowerCase();
    roleForm.append("role", Name);

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);
    
    return this.http.post(`${environment.API_URL}/${company}/roles/create`, roleForm, {
      headers: _headers,
    });
  }

  editRole(form) {
    let company = localStorage.getItem('sto_company');
    let roleForm = new FormData();
    let name = form.role.toString();
    let Name = name[0].toUpperCase() + name.substr(1).toLowerCase();
    roleForm.append("role", Name);

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);

    return this.http.post(`${environment.API_URL}/${company}/roles/${form._id}/edit`, roleForm, {
      headers: _headers,
    });
  }

  deleteRole(id) {
    let company = localStorage.getItem('sto_company');

    let headers = new HttpHeaders();
    let _headers = headers.append('authorization', `Bearer ${this._authService.accessToken}`);
    
    return this.http.delete(`${environment.API_URL}/${company}/roles/${id}/delete`, {
      headers: _headers,
    });
  }*/
}
