<div class="body w-full" *ngIf="loading_page">
    <mat-spinner [diameter]="60"></mat-spinner>
</div>
<div class="w-full sm:p-6 p-0" *ngIf="!loading_page">
    <div style="position: relative;">
        <input id="scrollTop" />
    </div>
    <div class="header">
        <h1 *ngIf="!editing && !watching" class="title"><strong>Usuario Nuevo</strong></h1>
        <h1 *ngIf="watching && !editProfile" class="title"><strong>Ver Usuario</strong></h1>
        <h1 *ngIf="editing && !editProfile" class="title"><strong>Editar Usuario</strong></h1>
        <h1 *ngIf="editProfile && !watching" class="title"><strong>Editar Perfil</strong></h1>
        <div *ngIf="watching && editProfile"
            class="prueba grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
            <h1 class="title" *ngIf="!data.profile" style="width: 100%;"><strong>Ver perfil</strong></h1>
            <button class="btnEdit" mat-flat-button (click)="goEdit()"
                type="button">Editar</button>
        </div>
        <button mat-icon-button class="ml-4" (click)="onNoClick()">
            <mat-icon class="close">close</mat-icon>
        </button>
    </div>

    <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4"
        [formGroup]="userForm">
        <mat-form-field>
            <mat-label>Nombre Completo<mat-label class="required">*</mat-label>
            </mat-label>
            <input matInput type="text" formControlName="full_name">
            <mat-hint>
                <ng-container *ngFor="let validation of validation_messages.required">
                    {{ userForm.get("full_name").hasError(validation.type) &&
                    (userForm.get("full_name").dirty || userForm.get("full_name").touched) ?
                    validation.message : ""}}
                </ng-container>
            </mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Email<mat-label class="required">*</mat-label>
            </mat-label>
            <input matInput type="text" formControlName="email">
            <mat-hint>
                <label *ngIf="email_used" class="required text">
                    El email ya está en uso</label>
                <ng-container *ngFor="let validation of validation_messages.email">
                    {{ userForm.get("email").hasError(validation.type) &&
                    (userForm.get("email").dirty || userForm.get("email").touched) ?
                    validation.message : ""}}
                </ng-container>
            </mat-hint>
        </mat-form-field>

        <!-- <mat-form-field class="password-field" *ngIf="!data.operacion">
            <mat-label>Contraseña actual</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password_1">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>
                <label *ngIf="pass" class="required text">
                    Contraseña incorrecta</label>
                <ng-container *ngFor="let validation of validation_messages.password">
                    {{ userForm.get("password_1").hasError(validation.type) &&
                    (userForm.get("password_1").dirty || userForm.get("password_1").touched) ?
                    validation.message : ""}}
                </ng-container>
            </mat-hint>
        </mat-form-field> -->

        <div class="block-password" *ngIf="!data.operacion">
            <div class="buttons">
                <button mat-icon-button [color]="'accent'" (click)="generatePassword()">
                    <mat-icon>password</mat-icon>
                </button>
            </div>
            <mat-form-field class="password-field">
                <mat-label>Contraseña<mat-label *ngIf="!editing" class="required">*</mat-label>
                </mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error>
                    <ng-container *ngFor="let validation of validation_messages.password">
                        {{ userForm.get("password").hasError(validation.type) &&
                        (userForm.get("password").dirty || userForm.get("password").touched) ?
                        validation.message : ""}}
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>



        <mat-form-field *ngIf="!data.operacion">
            <mat-label>Confirmar Contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>
                <!-- <ng-container *ngFor="let validation of validation_messages.confirmPassword">
                    {{ userForm.get("confirmPassword").hasError(validation.type) &&
                    (userForm.get("confirmPassword").dirty || userForm.get("confirmPassword").touched) ?
                    validation.message : ""}}
                </ng-container> -->
                <div class="validation" *ngIf="userForm.controls['confirmPassword'].errors?.MatchPassword">
                    Las contraseñas no coinciden
                </div>
            </mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Rol<mat-label class="required">*</mat-label>
            </mat-label>
            <mat-spinner *ngIf="role_loader" [diameter]="23"></mat-spinner>
            <mat-select formControlName="id_role">
                <mat-option *ngFor="let rol of roles" [value]="rol.id_role">{{ rol.title }}</mat-option>
            </mat-select>
            <mat-hint>
                <ng-container *ngFor="let validation of validation_messages.required">
                    {{ userForm.get("id_role").hasError(validation.type) &&
                    (userForm.get("id_role").dirty || userForm.get("id_role").touched) ?
                    validation.message : ""}}
                </ng-container>
            </mat-hint>
        </mat-form-field>

        <div id="togles" class="" style="display: flex; width: 50%; justify-content: flex-start;">
            <div>
                <label style="padding: 10px; padding-bottom: 25px;"><strong>Activo</strong></label>
                <mat-slide-toggle style="padding: 10px; padding-bottom: 25px;" formControlName="active">
                </mat-slide-toggle>
            </div>

            <div>
                <label style="padding: 10px; padding-bottom: 25px;"><strong>Doble Autenticación</strong></label>
                <mat-slide-toggle style="padding: 10px; padding-bottom: 25px;" 
                    (click)="editProfile ? validateTwoFactor():''" formControlName="two_factor">
                </mat-slide-toggle>
            </div>
        </div>
    </form>

    <div class="mt-6 btn_container">
        <button mat-flat-button class="save mr-3" *ngIf="!watching" 
        [disabled]="!userForm.valid || loading || (editing ? !dirty : '')"
            (click)="save()">
            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            <span *ngIf="!loading">Guardar</span>
        </button>

        <button mat-flat-button class="cancel mr-3" (click)="onNoClick()">
            Cancelar
        </button>

        <button mat-button color="primary" *ngIf="data.operacion == 'edit' || editProfile" (click)="changePassword()">Cambiar contraseña</button>
    </div>
</div>