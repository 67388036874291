import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TwoFactorComponent } from 'app/modules/auth/two-factor/two-factor.component';

//Services
import { UsersService } from '../../users.service';
import { AuthService } from 'app/core/auth/auth.service';
import { RolesService } from 'app/modules/admin/roles/roles.service';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-modals-form',
  templateUrl: './modals-form.component.html',
  styleUrls: ['./modals-form.component.scss']
})
export class ModalsFormComponent implements OnInit {
  userForm: FormGroup;

  role_loader: Boolean = false;
  email_used = false;
  pass = false;
  hide: Boolean = true;
  watching = false;
  editing = false;
  editProfile = false;
  email;

  loading = false;
  loading_page = false;

  dirty = false;

  roles: any = [];
  user;

  validation_messages = {
    required: [
      { type: "required", message: 'Este campo es requerido' }
    ],
    email: [
      { type: "required", message: 'Email es requerido' },
      { type: "pattern", message: 'Email invalido' }
    ],
    password: [
      { type: "required", message: 'Email es requerido' },
      { type: "minLength", message: 'Minímo 3 caracteres' }
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<ModalsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private router: Router,
    private routeActivate: ActivatedRoute,
    private _usersService: UsersService,
    private roleService: RolesService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;

    this.userForm = this.formBuilder.group({
      full_name: new FormControl('', Validators.required),
      email: new FormControl("",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")])),
      id_role: new FormControl('', Validators.required),
      active: new FormControl(true),
      two_factor: new FormControl(true),
      password_1: new FormControl(''),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3)])),
      confirmPassword: new FormControl('')
    },
      {
        validator: this.MatchPassword,
      });
  }

  ngOnInit(): void {
    this.getRoles();

    if (this.data.id_user) {
      this.getUser();
      if (this.data.operacion == 'view') {
        this.watching = true;
        this.editing = false;

        this.userForm.disable();
      } else if (this.data.operacion == 'edit') {
        this.watching = false;
        this.editing = true;
        let user = this.userForm.value;
        this.userForm.get('email').disable();
        this.userForm.get('password_1').clearValidators();
        this.userForm.get('password').clearValidators();
        this.userForm.valueChanges.subscribe((form) => {

          let changesCount = 0;

          Object.entries(form).forEach(([key, value]) => {
            if (user[key] != value) {
              changesCount++;
            }
          });

          this.dirty = changesCount > 0;
        });
      } else if (this.data.operacion == 'edit profile') {
        this.watching = true;
        this.editing = false;
        this.editProfile = true;

        this.userForm.disable();
        this.userForm.get('password_1').clearValidators();
        this.userForm.get('password').clearValidators();
      }
    }
  }

  getUser() {
    this.loading_page = true;
    this._usersService.getUser(this.data.id_user).subscribe((response: any) => {
      /* this.userForm.patchValue(response.data)
      console.log(response) */
      this.user = response.data;
      this.userForm.get('full_name').setValue(response.data.full_name);
      this.userForm.get('email').setValue(response.data.email);
      this.userForm.get('id_role').setValue(response.data.id_role);
      this.email = response.data.email;

      if (response.data.active == 0) {
        this.userForm.get('active').setValue(false);
      } else {
        this.userForm.get('active').setValue(true);
      }

      if (response.data.two_factor == 0) {
        this.userForm.get('two_factor').setValue(false);
      } else {
        this.userForm.get('two_factor').setValue(true);
      }
      this.loading_page = false;
    });
  }

  getRoles() {
    this.role_loader = true;
    this.roleService.listRoles('asc').subscribe((response: any) => {
      if (response.status == 200) {
        this.roles = response.data;
      }
      this.role_loader = false;
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }

  changePassword() {
    const dialogRefForm = this.dialog.open(ChangePasswordDialogComponent, {
      panelClass: 'dialog-password',
      data: { id_user: this.data.id_user }
    })

    dialogRefForm.afterClosed().subscribe(result => { });
  }

  validateTwoFactor(){
    if(!this.userForm.value.two_factor){
      if (this.user.secret_authentication == null) {
        this._authService.getQrCode(this.email).subscribe((res: any) => {
          if (res.status == 200) {
            const twoFactor = this.dialog.open(TwoFactorComponent, {
              panelClass: 'dialog-two-factor',
              height: '90%',
              data: {
                qr: res.qrCodeLink,
                key: res.secret_key,
                email: this.email,
                password: '',
                status: res.status
              }
            });

            twoFactor.afterClosed().subscribe(result => {
              if(!result.active){
                this.save();
              }
            });
          }
        })
      }
    }
  }

  goEdit() {
    this.userForm.enable();
    let user = this.userForm.value;
    this.userForm.get('email').disable();
    this.userForm.valueChanges.subscribe((form) => {

      let changesCount = 0;

      Object.entries(form).forEach(([key, value]) => {
        if (user[key] != value) {
          changesCount++;
        }
      });

      this.dirty = changesCount > 0;
    });
    this.watching = false;
    this.editing = true;
  }

  save() {
    let form = this.userForm.value;
    this.loading = true;

    if (this.editing && !this.editProfile) {
      if (form.active) {
        form.active = '1';
      } else {
        form.active = '0';
      }

      if (form.two_factor) {
        form.two_factor = '1';
      } else {
        form.two_factor = '0';
      }

      form.id_user = this.data.id_user;

      this._usersService.editUser(form).subscribe((response: any) => {
        if (response.status == 200) {
          if (form.password) {

          } else {
            this.dialogRef.close('updated');
            this._snackBar.open("Usuario editado exitosamente", "ok", { duration: 2000 });
          }
        } else {
          alert(response.message)
        }
        this.loading = false;
      })

    } else if (this.editProfile) {
      if (form.active) {
        form.active = '1';
      } else {
        form.active = '0';
      }

      if (form.two_factor) {
        form.two_factor = '1';
      } else {
        form.two_factor = '0';
      }

      form.id_user = this.data.id_user;
      this._usersService.editProfile(form).subscribe((res: any) => {
        if (res.status == 200) {
          this.watching = true;
          this.editing = false;
          this.dirty = false;
          this.editProfile = true;
          this.userForm.disable();
          this._snackBar.open("Usuario editado exitosamente", "ok", { duration: 2000 });
        } else {
          alert(res.message)
        }
        this.loading = false;
      })
    } else {
      if (form.active) {
        form.active = '1';
      } else {
        form.active = '0';
      }

      if (form.two_factor) {
        form.two_factor = '1';
      } else {
        form.two_factor = '0';
      }

      this._usersService.createUser(form).subscribe((response: any) => {
        if (response.status == 200) {
          this.dialogRef.close('updated');
          this._snackBar.open("Usuario creado exitosamente", "ok", { duration: 2000 });
        } else {
          if (response.message == 'Email Already Taked') {
            this.email_used = true;
            this.scrollToTop();
            setTimeout(() => this.email_used = false, 3000);
          } else {
            alert(response.message);
          }
        }
        this.loading = false;
      })
    }
  }

  scrollToTop() {
    document.getElementById("scrollTop").focus();
  }

  private MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value // to get value in input tag
    const passconfirm = AC.get('confirmPassword').value // to get value in input tag
    if (password != passconfirm) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      AC.get('confirmPassword').setErrors(null);
    }
  }

  generatePassword() {
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '{', '[', '}', ']', '>', '<', '.', '?', '/'];
    let capitalLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    let lowercase = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

    let passwordG = '';

    for (let i = 0; i < 1; i++) {
      passwordG += numbers[Math.floor(Math.random() * numbers.length)];
      passwordG += symbols[Math.floor(Math.random() * symbols.length)];
      passwordG += capitalLetters[Math.floor(Math.random() * capitalLetters.length)];
    }

    for (let o = 0; o < 5; o++) {
      passwordG += lowercase[Math.floor(Math.random() * lowercase.length)];
    }

    let passFin = passwordG.split('');
    let pass = '';
    let j, k;

    for (let e = passFin.length; e; e--) {
      j = Math.floor(Math.random() * e);
      k = passFin[e - 1];
      passFin[e - 1] = passFin[j];
      passFin[j] = k;
    }

    pass = passFin.toString();
    pass = pass.replace(/,/g, "");

    this.userForm.patchValue({
      password: pass,
      confirmPassword: pass
    });
  }

}
